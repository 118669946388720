<template>
  <div>
    <div class="row">
      <div class="card">
        <div class="card-body">
          <!-- Filter -->
          <div class="row justify-content-end mt-n3">
            <div class="col-md-12">
              <b-input-group>
                <template #prepend>
                  <b-button squared @click="pagination" variant="success"
                    >Tampilkan</b-button
                  >
                </template>
                <b-form-input
                  id="input-trigger-modal-patient"
                  v-model="display.patient_name"
                  placeholder="Pilih Pasien"
                  readonly
                  @click="$bvModal.show('modal-patient')"
                >
                </b-form-input>
              </b-input-group>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="dataLoad == true">
      <div class="col-12">
        <b-button
          squared
          class="mx-1"
          size="sm"
          variant="primary"
          @click="btnPrintOnClick"
          >Print</b-button
        >
        <b-button
          squared
          :class="'mx-2'"
          size="sm"
          variant="info"
          @click="btnDownloadOnClick"
          >Download</b-button
        >
      </div>

      <Print :data="data" :items="items" />

      <!-- PDF -->
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        :filename="'Slip Gaji-' + data.employee_name"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="800px"
        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
      >
        <section slot="pdf-content">
          <Print :data="data" :items="items" v-if="dataLoad == true" />
        </section>
      </vue-html2pdf>
    </div>
    <Modal @chosenPatient="setPatient" @submitedPatient="setPatient" />
  </div>
</template>

<script>
import Print from "@/component/patients/ThemplateMedicalRecord.vue";
import VueHtml2pdf from "vue-html2pdf";
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Modal from "@/component/general/ModalPatient.vue";
export default {
  components: {
    Print,
    VueHtml2pdf,
    Modal,
  },

  data() {
    return {
      // Data
      data: {},
      items: [],
      dataLoad: false,
      filter: {
        start_date: null,
        end_date: null,
        id: null,
      },
      display: {
        patient_name: null,
      },
    };
  },

  methods: {
    // filter
    resetBirtDate() {
      this.filter.birt_date = "";
      this.pagination();
    },

    setPatient(value) {
      this.filter.id = value.id;
      this.display.patient_name = value.name;
      this.$bvModal.hide("modal-patient");
    },
    generalFilter(sended) {
      this.filter.start_date = sended.start_date;
      this.filter.end_date = sended.end_date;
    },

    async pagination() {
      let filterParams = `&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let response = await module.paginate(
        "medical-record-reports/" + this.filter.id,
        `?${filterParams}`
      );
      this.items = response.data;
      this.getPatient();
      this.dataLoad = true;
    },

    async getPatient() {
      let response = await module.get("patients/" + this.filter.id);
      this.data = response;
      if (this.display.patient_name == null) {
        this.display.patient_name =
          this.data.name + " (" + this.data.patient_id + ")";
      }
    },

    btnPrintOnClick() {
      this.$htmlToPaper("print");
    },

    btnDownloadOnClick() {
      this.$refs.html2Pdf.generatePdf();
    },

    onProgress($event) {
      console.log("onProgress", $event);
    },

    hasStartedGeneration() {
      console.log("hasStartedGeneration");
    },

    hasGenerated($event) {
      console.log("hasGenerated", $event);
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Cetak Blangko Rekam Medis" },
      { title: "Daftar Cetak Blangko Rekam Medis", route: "/patients/case" },
      { title: "Print" },
    ]);

    this.filter.id = this.$route.params.id;

    if (this.filter.id != null) {
      this.pagination();
    }
  },
};
</script>

<style>
</style>